import { openDB } from 'idb'

interface VaultFileState {
    isReadingFile: boolean
    isWritingFile: boolean
    base64: string
}

type VaultFileParams = (
    {
        // When creating a new file
        file: File,
        onWrite?: () => void
    } | {
        // When opening an existing file
        url: string,
        onRead?: () => void
    }
)

export class VaultFile {
    id: string = ''
    url: string = ''
    file?: File
    state = reactive<VaultFileState>({
        isReadingFile: false,
        isWritingFile: false,
        base64: '',
    }) as VaultFileState

    constructor(params: VaultFileParams) {
        if ('file' in params) {
            this.file = params.file
            this.id = randomId()

            this.state.isWritingFile = true
            this.writeFile()
                .catch(console.error)
                .finally(() => {
                    if (params.onWrite)
                        params.onWrite()

                    this.state.isWritingFile = false
                })
        }
        else if ('url' in params) {
            this.url = params.url
            this.state.isReadingFile = true
            this.readFile().finally(() => {
                this.state.isReadingFile = false
                if (params.onRead)
                    params.onRead()
            })
        }
    }

    private async readFile() {
        const vaultKey = await getVaultKey()

        if (!this.url.startsWith('vault://'))
            throw new Error('Invalid vault file url')

        const fileId = this.url.slice(8)

        // Try to get the file from the IDB cache
        const cachedFile = await this.fetchFromCache()
        if (cachedFile) {
            this.file = cachedFile
            fileToBase64(this.file!).then((base64) => {
                this.state.base64 = base64
            })
            return
        }

        const response = await fetch(`https://vault.tela.com/v1/file/${fileId}`, {
            method: 'GET',
            headers: {
                'X-Vault-Key': vaultKey as string,
            },
        })

        if (!response.ok) {
            const error = await response.json()
            throw new Error(`Error writing file: ${error.message}`)
        }

        const fileName = response.headers.get('content-disposition')?.match(/filename="(.*)"/)?.[1] ?? 'untitled'
        const fileType = response.headers.get('content-type') ?? 'application/octet-stream'

        const file = new File([await response.arrayBuffer()], fileName, { type: fileType })

        this.updateCache()

        cleanExpiredFiles()

        this.file = file
        fileToBase64(this.file!).then((base64) => {
            this.state.base64 = base64
        })

        return file
    }

    private async writeFile() {
        const vaultKey = await getVaultKey()
        const fileHash = await getFileHash(this.file!)

        const formData = new FormData()
        formData.append('file', this.file!)

        const response = await fetch(`https://vault.tela.com/v1/file/${fileHash}`, {
            method: 'POST',
            headers: {
                'X-Vault-Key': vaultKey as string,
            },
            body: formData,
        })

        if (!response.ok) {
            const error = await response.json()
            throw new Error(`Error writing file: ${error.message}`)
        }

        this.url = `vault://${fileHash}`
        this.id = fileHash
        fileToBase64(this.file!).then((base64) => {
            this.state.base64 = base64
        })

        this.updateCache()

        return {
            url: this.url,
        }
    }

    private async fetchFromCache() {
        if (!import.meta.client)
            return

        const db = await getDb()
        const entry = await db.get('vault-files', this.id)
        if (entry) {
            const isExpired = entry.expiresAt && entry.expiresAt > new Date()
            if (isExpired)
                await db.delete('vault-files', this.id)
            else
                return entry.file
        }
    }

    private async updateCache() {
        if (!import.meta.client)
            return

        const db = await getDb()
        await db.put('vault-files', {
            file: this.file,
            expiresAt: new Date(new Date().getTime() + 30 * 60 * 1000),
            id: this.id,
            url: this.url,
        }, this.id)
    }

    static async fromUrlList(urls: string[]) {
        const promises = urls.map((url) => {
            return new Promise<VaultFile>((resolve) => {
                const file = new VaultFile({
                    url,
                    onRead: () => {
                        resolve(file)
                    },
                })
            })
        })

        return await Promise.all(promises)
    }
}

async function getVaultKey() {
    const cookieValue = useCookie('tela-vault-key')
    if (cookieValue.value)
        return cookieValue.value

    const { api } = useAPI()
    const newKey = await api().vault.key.get({})
    if (newKey.error)
        throw newKey.error

    const newCookie = useCookie('tela-vault-key', {
        expires: new Date(new Date().getTime() + 30 * 60 * 1000),
    })
    newCookie.value = newKey.data.key ?? undefined

    return newKey.data.key
}

async function getDb() {
    return openDB('tela', 1, {
        upgrade(db) {
            db.createObjectStore('vault-files')
        },
    })
}

async function cleanExpiredFiles() {
    if (!import.meta.client)
        return

    const db = await getDb()
    const files = await db.getAll('vault-files')

    for (const file of files) {
        if (file.expiresAt && file.expiresAt < new Date())
            await db.delete('vault-files', file.id)
    }
}

export async function getFileHash(blob: Blob): Promise<string> {
    const arrayBuffer = await blob.arrayBuffer()
    const hashBuffer = await crypto.subtle.digest('SHA-256', arrayBuffer)
    const hashArray = Array.from(new Uint8Array(hashBuffer)) // convert buffer to byte array
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('') // convert bytes to hex string
    return hashHex
}

export type VaultFileInstance = InstanceType<typeof VaultFile>
